import React from "react";
import PropTypes from "prop-types";
import Content, {HTMLContent} from "../components/Content";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import SEO from "../components/SEO";

// eslint-disable-next-line
const TeamMember = (props) => {
  const data = props.data.markdownRemark;
  const name = data.frontmatter.title;
  const teamTitle = data.frontmatter.teamtitle;
  const teamBio = data.frontmatter.teambio;
  const featuredImage = data.frontmatter.featuredimage;
  const PageContent = HTMLContent || Content;
  const seo = {
    pageTitle: `${name} | ${teamTitle} | HOF Capital`,
    fields: {
      slug: data.fields.slug,
    },
    frontmatter: {
      seo: {
        title: props.data.frontmatter?.seo.title || name,
        description:
          props.data.frontmatter?.seo.description ||
          `${teamTitle} at HOF Capital`,
        image: {
          publicURL:
            props.data.frontmatter?.seo.image.publicURL ||
            featuredImage.publicURL,
        },
      },
    },
  };
  return (
    <React.Fragment>
      <SEO {...seo} />
      <Helmet bodyAttributes={{ class: "page-team-member" }} />
      <section className="team-member-section section">
        <Link to="/team" className="back-to-team-link"></Link>
          <div>
            <div className="page-title">
              <Link to="/team" className="back-to-team-link"></Link>
              <h6 className="has-text-centered red">Our Team</h6>
              <h1 className="title has-text-centered">{name}</h1>
            </div>
            <div className="content">
              <div className="columns is-variable is-3-tablet is-8 is-justify-content-center">
                <div className="column is-6">
                  <Link to="/team" className="back-to-team-link"></Link>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: featuredImage,
                      alt: `featured image thumbnail for team member ${name}`,
                      width: 350,
                      height: 250
                    }}
                  />
                </div>
                <div className="column is-6">
                  <span className="team-position">{teamTitle}</span>
                  <PageContent content={teamBio} className="team-bio"></PageContent>
                </div>
              </div>
            </div>
          </div>
      </section>
    </React.Fragment>
  );
};

TeamMember.propTypes = {
  title: PropTypes.string,
  teamtitle: PropTypes.string,
  teambio: PropTypes.string,
  featuredimage: PropTypes.object
};

export default TeamMember;

export const pageQuery = graphql`
query TeamMemberByID($id: String!) {
  markdownRemark(id: {eq: $id}) {
    fields {
      slug
    }
    frontmatter {
      title
      teamtitle
      teambio
      featuredimage {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 1400, height: 1050, transformOptions: {fit: COVER, cropFocus: CENTER})
        }
      }
    }
  }
  seo: markdownRemark(id: {eq: $id}) {
    ...SEOFragment
  }
}
`;
